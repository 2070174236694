import { svgs } from '@/utils/Images';
import { ButtonHTMLAttributes, ReactElement } from 'react';

export const ButtonWithLoading = (props: { title: string; isLoading: boolean } & ButtonHTMLAttributes<HTMLButtonElement>): ReactElement => {
  const { disabled, isLoading, type, className, onClick, title, ...rest } = props
  return (
    <button
      {...rest}
      className={`flex items-center justify-center ${className || ''}`}
      disabled={isLoading ? true : disabled}
      type={type ?? 'button'}
      onClick={e => {
        if (!isLoading && onClick) {
          e.preventDefault()
          onClick(e)
        }
      }}
    >
      <svg
        className={`mr-2 h-5 w-5 animate-spin text-amber-v1 ${isLoading ? 'block' : 'hidden'}`}
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
      >
        {svgs.refresh}
      </svg>

      {title}
    </button>
  )
}

export default ButtonWithLoading
